import { createStore } from 'redux';
import rootReducer from './rootReducer';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const store = createStore(
  rootReducer, 
  undefined, 
  composeEnhancers && composeEnhancers());

export default store;
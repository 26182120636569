export const ACTION_SHOW_NOTIFICATION =
  "app:redux:shared:ACTION_SHOW_NOTIFICATION";
export const ACTION_SHOW_ERROR_NOTIFICATION =
  "app:redux:shared:ACTION_SHOW_ERROR_NOTIFICATION";
export const ACTION_SHOW_SUCCESS_NOTIFICATION =
  "app:redux:shared:ACTION_SHOW_SUCCESS_NOTIFICATION";
export const ACTION_SHOW_WARNING_NOTIFICATION =
  "app:redux:shared:ACTION_SHOW_WARNING_NOTIFICATION";

export const ACTION_UPDATE_NAVBAR_INFO =
  "app:redux:shared:ACTION_UPDATE_NAVBAR_INFO";

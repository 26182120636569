import React, { useState, useEffect, useRef, FC } from "react";
import TweenOne from "rc-tween-one";

import ticker from "rc-tween-one/lib/ticker";
import imageHomeLogo from "../../assets/images/HomeLogo.png";

const Home: FC<HomeProps> = (props: HomeProps) => {
  const dom = useRef<HTMLDivElement | null>(null);
  const sideBoxComp = useRef<TweenOne<any> | null>(null);
  const logoInfoRef = useRef({
    logoWidth: 400,
    logoHeight: 400,
    logoPointSizeInPixel: 20,
    logoPointSizeMin: 10,
    timeIntervalGather: 3000,
    tickerInterval: -1,
  });
  const [logoState, setLogoState] = useState<{
    mounted: boolean;
    isGathered: boolean;
    logoGatherBoxAnim: {
      opacity?: number;
      type?: string;
      duration?: number;
    };
    logoGatherChildren: React.FunctionComponentElement<{
      animation: {
        x: number;
        y: number;
        opacity?: number;
        duration?: number;
        ease?: string;
        delay?: number;
        repeat?: number;
        yoyo?: boolean;
        scale?: number;
      };
      style?: {
        left?: number;
        right?: number;
        top?: number;
        bottom?: number;
      };
    }>[];
  }>({
    mounted: false,
    isGathered: true,
    logoGatherBoxAnim: {},
    logoGatherChildren: [],
  });

  useEffect(() => {
    setLogoState({
      ...logoState,
      mounted: true,
    });
    createPointData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPointData = () => {
    const canvas: HTMLCanvasElement = document.getElementById(
      "homeLogoCanvas"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const logoWidth = logoInfoRef.current.logoWidth;
    const logoHeight = logoInfoRef.current.logoHeight;
    ctx?.clearRect(0, 0, logoWidth, logoHeight);
    canvas.width = logoWidth;
    canvas.height = logoHeight;
    const img = new Image();

    img.onload = () => {
      ctx?.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        logoWidth,
        logoHeight
      );
      const imageData = ctx?.getImageData(0, 0, logoWidth, logoHeight);
      setDataToDom(imageData?.data);
      dom.current?.removeChild(canvas);
    };
    img.crossOrigin = "anonymouse";
    // debug(`imageHomeLogo: ${imageHomeLogo}`);
    img.src = `${imageHomeLogo}?_=${new Date().getTime()}`;
  };

  const setDataToDom = (data?: Uint8ClampedArray) => {
    if (data) {
      const pointArray = [];
      const {
        logoWidth,
        logoHeight,
        logoPointSizeInPixel,
        logoPointSizeMin,
        tickerInterval,
        timeIntervalGather,
      } = logoInfoRef.current;

      for (let i = 0; i < logoWidth; i += logoPointSizeInPixel) {
        for (let j = 0; j < logoHeight; j += logoPointSizeInPixel) {
          if (data[(j * logoWidth + i) * 4 + 3] > 50) {
            pointArray.push({ x: i, y: j });
          }
        }
      }
      const children: JSX.Element[] = [];
      pointArray.forEach((point, i) => {
        const r = Math.random() * logoPointSizeMin + logoPointSizeMin;
        const b = Math.random() * 0.4 + 0.1;
        children.push(
          <TweenOne
            style={{ position: "absolute", left: point.x, top: point.y }}
            key={i}
          >
            <TweenOne
              style={{
                borderRadius: "100%",
                width: r,
                height: r,
                opacity: b,
                backgroundColor: `rgb(${Math.round(
                  Math.random() * 100
                )},100,255)`,
              }}
              animation={{
                y: (Math.random() * 2 - 1) * 10 || 5,
                x: (Math.random() * 2 - 1) * 5 || 2.5,
                delay: Math.random() * 1000,
                repeat: -1,
                duration: 3000,
                yoyo: true,
                ease: "easeInOutQuad",
              }}
            />
          </TweenOne>
        );
      });

      setLogoState({
        ...logoState,
        logoGatherChildren: children,
        logoGatherBoxAnim: { opacity: 0, type: "from", duration: 800 },
      });

      ticker.clear(tickerInterval);
      if (logoState.mounted) {
        logoInfoRef.current.tickerInterval = ticker.interval(
          updateTweenData,
          timeIntervalGather
        );
      }
    }
  };

  const updateTweenData = () => {
    const { tickerInterval } = logoInfoRef.current;
    if (tickerInterval < 0) {
      return;
    }
    if (logoState.isGathered) {
      disperseData();
    } else {
      gatherData();
    }
    setLogoState({
      ...logoState,
      isGathered: !logoState.isGathered,
    });
  };

  const gatherData = () => {
    const children = logoState.logoGatherChildren.map((child) =>
      React.cloneElement(child, {
        animation: {
          x: 0,
          y: 0,
          opacity: 1,
          scale: 1,
          delay: Math.random() * 500,
          duration: 800,
          ease: "easeInOutQuint",
        } as any,
      })
    );
    setLogoState({
      ...logoState,
      logoGatherChildren: children,
    });
  };

  const disperseData = () => {
    if (dom.current && sideBoxComp.current) {
      const rect = dom.current.getBoundingClientRect();
      const sideRect = (sideBoxComp.current as any).getBoundingClientRect();
      const sideTop = sideRect.top - rect.top;
      const sideLeft = sideRect.left - rect.left;
      const children = logoState.logoGatherChildren.map((child) =>
        React.cloneElement(child, {
          animation: {
            x:
              Math.random() * rect.width -
              sideLeft -
              (child.props.style?.left || 0),
            y:
              Math.random() * rect.height -
              sideTop -
              (child.props.style?.top || 0),
            opacity: Math.random() * 0.4 + 0.1,
            duration: Math.random() * 500 + 500,
            ease: "easeInOutQuint",
          },
        })
      );
      setLogoState({
        ...logoState,
        logoGatherChildren: children,
      });
    }
  };

  return (
    <div
      ref={dom}
      style={{
        position: "relative",
        overflow: "hidden",
        height: "calc(100vh - 64px)",
        backgroundColor: "rgb(0, 0, 51)",
      }}
    >
      <div className="homePageHeading">IMPLICIT CAST</div>
      <canvas id="homeLogoCanvas" />
      <TweenOne
        ref={sideBoxComp}
        animation={logoState.logoGatherBoxAnim as any}
        style={{
          width: `${logoInfoRef.current.logoWidth}px`,
          height: `${logoInfoRef.current.logoHeight}px`,
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          margin: "auto",
          pointerEvents: "none",
        }}
      >
        {logoState.logoGatherChildren}
      </TweenOne>
    </div>
  );
};

export default Home;

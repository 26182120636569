import React, { FC } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Menu from "../menus";
import logoImg from "../../assets/images/HomeLogo.png";

const StyledHeader = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 0 !important;
`;

const Navbar: FC<INavbarProps> = ({ menusProps }) => {
  const isBigScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const renderHeaderLogo = () => {
    return isBigScreen ? (
      <img
        src={logoImg}
        alt="Implicit Cast"
        style={{ height: "40px", margin: "20px" }}
      />
    ) : null;
  };

  return (
    <StyledHeader>
      {renderHeaderLogo()}
      <Menu {...menusProps} />
    </StyledHeader>
  );
};

export default Navbar;

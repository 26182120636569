import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import ReactNotification from "react-notifications-component";

import store from "./redux/store";
import Home from "./page/home";
import RedirectPage from "./page/redirect";
import Navbar from "./components/navbar";
import "./styles/index.scss";
import "antd/dist/antd.dark.min.css";

const AppRouter: FC<IAppRouter> = () => {
  const navbarInfo = useSelector((state: IState) => state.shared.navbarInfo);
  navbarInfo.menusProps.onClick = (event, menuInfo) => {
    console.log("menuInfo: ", menuInfo);
  };
  return (
    <>
      <Router>
        <Navbar menusProps={navbarInfo.menusProps} />
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/viewport">
            <RedirectPage />
          </Route>
          <Route path="*">
            <div>Not Found</div>
          </Route>
        </Switch>
      </Router>
    </>
  );
};

const App: FC<IApp> = () => {
  return (
    <Provider store={store}>
      <ReactNotification />
      <AppRouter />
    </Provider>
  );
};

export default App;

import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";

const Redirect: FC<RedirectProps> = ({ url }) => {
  const location = useLocation();
  useEffect(() => {
    if (url) {
      window.location.href = url;
    } else {
      window.location.href = location.pathname + "/";
    }
  }, [url, location.pathname]);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        height: "calc(100vh - 64px)",
        backgroundColor: "rgb(0, 0, 51)",
      }}
    >
      <Spin
        size="large"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
};

export default Redirect;

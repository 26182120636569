/**
 *
 * StyledIcon
 *
 */

import React, { FC } from "react";
import Loadable from "react-loadable";

const Icon: FC<IIconProps> = ({ iconPath, ...otherProps }) => {
  if (iconPath && iconPath.includes("/")) {
    const iconPack = iconPath.split("/")[0];
    const iconName = iconPath.split("/")[1];

    const LoadableIcon = Loadable({
      loading: () => null,
      loader: () => import(`react-icons/${iconPack}/index.js`),
      render: (loaded) => {
        const LoadedIcon = loaded[iconName];
        if (LoadedIcon) {
          return <LoadedIcon {...otherProps} />;
        }
        return null;
      },
    });

    return <LoadableIcon />;
  }
  return null;
};

export default Icon;

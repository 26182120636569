import React, { FC } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Icon from "../icons";

const TopMenu: FC<IMenusProps & { compact: boolean }> = ({
  compact,
  children,
  menuMode,
  menuTheme,
  selectedMenu,
}) => {
  return compact ? (
    <Menu mode={menuMode} theme={menuTheme}>
      <Menu.SubMenu title={<Icon iconPath="fa/FaBars" />}>
        {children}
      </Menu.SubMenu>
    </Menu>
  ) : (
    <Menu mode={menuMode} theme={menuTheme} selectedKeys={selectedMenu}>
      {children}
    </Menu>
  );
};

const Menus: FC<IMenusProps> = (props) => {
  const isBigScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const onMenuClick = props.onClick;

  const renderMenus = (menusInfo: IMenuProps[]) => {
    const menuItems: JSX.Element[] = [];
    menusInfo.forEach((menuInfo) => {
      switch (menuInfo.typeInfo) {
        case "menuItem":
          menuItems.push(renderMenuItem(menuInfo));
          break;
        case "subMenu":
          menuItems.push(renderSubmenu(menuInfo));
          break;
        case "menuItemGroup":
          menuItems.push(renderMenuItemGroup(menuInfo));
          break;
        default:
          break;
      }
    });
    return menuItems.length ? menuItems : null;
  };

  const renderMenuItem = (menuInfo: IMenuProps) => (
    <Menu.Item
      key={menuInfo.id}
      id={menuInfo.id}
      onClick={(event) => onMenuClick?.(event, menuInfo)}
      style={
        props.menuMode !== "horizontal"
          ? { textAlign: menuInfo.collapsed ? "center" : "left" }
          : {}
      }
      className={props.menuMode !== "horizontal" ? "force-padding-zero" : ""}
    >
      {renderMenuLabel(menuInfo)}
    </Menu.Item>
  );

  const renderSubmenu = (menuInfo: IMenuProps) => (
    <Menu.SubMenu
      key={menuInfo.id}
      title={renderMenuLabel(menuInfo)}
      onTitleClick={(event) => onMenuClick?.(event, menuInfo)}
    >
      {menuInfo.menus ? renderMenus(menuInfo.menus) : null}
    </Menu.SubMenu>
  );

  const renderMenuItemGroup = (menuInfo: IMenuProps) => (
    <Menu.ItemGroup key={menuInfo.id} title={renderMenuLabel(menuInfo)}>
      {renderMenuLabel(menuInfo)}
    </Menu.ItemGroup>
  );

  const renderMenuLabel = (menuInfo: IMenuProps) =>
    menuInfo.link ? (
      <Link to={menuInfo.link}>
        <span key="menuLabelIcon" className="anticon">
          <Icon iconPath={menuInfo.icon} />
        </span>
        <span key="menuLabelText">{menuInfo.label}</span>
      </Link>
    ) : (
      <>
        <span key="menuLabelIcon" className="anticon">
          <Icon iconPath={menuInfo.icon} />
        </span>
        ,<span key="menuLabelText">{menuInfo.label}</span>
      </>
    );

  return (
    <TopMenu {...props} compact={!isBigScreen}>
      {renderMenus(props.menus)}
    </TopMenu>
  );
};

export default Menus;

import {
  ACTION_SHOW_NOTIFICATION,
  ACTION_SHOW_SUCCESS_NOTIFICATION,
  ACTION_SHOW_ERROR_NOTIFICATION,
  ACTION_SHOW_WARNING_NOTIFICATION,
  ACTION_UPDATE_NAVBAR_INFO,
} from "./constants";

export const DEFAULT_NOTIFICATION_INFO: INotificationInfo = {
  title: "",
  message: "",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
};

const initalNavBarProps: INavbarProps = {
  menusProps: {
    menuTheme: "dark",
    selectedMenu: ["menuItemHome"],
    menuMode: "horizontal",
    menus: [
      {
        id: "menuItemHome",
        typeInfo: "menuItem",
        icon: "fa/FaHome",
        label: "Home",
        link: "/home",
      },
      {
        id: "menuItemUiViewport",
        typeInfo: "menuItem",
        icon: "ai/AiTwotoneBoxPlot",
        label: "UI Viewport",
        link: "/viewport",
      },
    ],
  },
};

const initialState: {
  notification: INotificationInfo;
  navbarInfo: INavbarProps;
} = {
  notification: {},
  navbarInfo: initalNavBarProps,
};

function sharedReducer(
  state = initialState,
  action:
    | IActionShowNotification
    | IActionShowNotificationInfo
    | IActionUpdateNavbarInfo
) {
  switch (action.type) {
    case ACTION_SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...DEFAULT_NOTIFICATION_INFO,
          ...(action as IActionShowNotificationInfo).notificationInfo,
        },
      };
    case ACTION_SHOW_SUCCESS_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...DEFAULT_NOTIFICATION_INFO,
          type: "success",
          title: (action as IActionShowNotification).title,
          message: (action as IActionShowNotification).message,
        },
      };
    case ACTION_SHOW_ERROR_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...DEFAULT_NOTIFICATION_INFO,
          type: "danger",
          title: (action as IActionShowNotification).title,
          message: (action as IActionShowNotification).message,
        },
      };
    case ACTION_SHOW_WARNING_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...DEFAULT_NOTIFICATION_INFO,
          type: "warning",
          title: (action as IActionShowNotification).title,
          message: (action as IActionShowNotification).message,
        },
      };
    case ACTION_UPDATE_NAVBAR_INFO:
      return {
        ...state,
        navbarInfo: (action as IActionUpdateNavbarInfo).navbarInfo,
      };
    default:
      return state;
  }
}

export default sharedReducer;
